import PageTitle from '@/components/common/pageTitle';
import InvoicesTabs from '@/components/tabs/invoiceTabs';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Process() {
  const { t } = useTranslation('invoices');
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '15px',
      }}
    >
      <PageTitle title={t('invoices')} />
      <InvoicesTabs />
    </Box>
  );
}
export default Process;
