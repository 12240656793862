import { DateTime } from 'luxon';

export default function formatDateMonthYear(date: string | undefined, language: string): string {
  return date ? `${DateTime.fromISO(date).setLocale(language).monthLong}/${DateTime.fromISO(date).year}` : `--/--`;
}

const formatDate = (date: string | undefined, language: string): string =>
  date ? `${DateTime.fromISO(date).setLocale(language).toLocaleString()}` : '';

const formatTime = (date: string | undefined): string =>
  date
    ? `${DateTime.fromISO(date).hour.toString().padStart(2, '0')}:${DateTime.fromISO(date)
        .minute.toString()
        .padStart(2, '0')}h`
    : `-`;

export { formatDate, formatTime };
