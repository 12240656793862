import { Typography } from '@mui/material';

interface PageTitleProps {
  title: string;
}

export default function PageTitle(props: PageTitleProps) {
  const { title } = props;
  return (
    <Typography
      sx={{
        fontWeight: 'bold',
        fontSize: 18,
      }}
    >
      {title}
    </Typography>
  );
}
