import Agencies from '@/pages/agencies';
import PersonProductRoles from '@/pages/personProductRoles';
import ErpProducts from '@/pages/products';
import Redirect from '@/pages/redirecting';
import PERMISSION_ROLES, { PAGE_NAMES } from '@constants/roles';
import RequireAuth from '@features/auth/RequireAuth';
import RequireLogin from '@features/auth/RequireLogin';
import Layout from '@pages/layout';
import Login from '@pages/login';
import Process from '@pages/process';

import { Route, Routes } from 'react-router-dom';

function AppRoute() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="/login" element={<Login />} />
      <Route element={<RequireLogin />}>
        <Route element={<RequireAuth roles={PERMISSION_ROLES[PAGE_NAMES.DASHBOARD]} />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Redirect />} />
            <Route path="/process" element={<Process />} />
            <Route path="/agencies" element={<Agencies />} />
            <Route path="/products" element={<ErpProducts />} />
            <Route element={<RequireAuth roles={PERMISSION_ROLES[PAGE_NAMES.ADMIN]} />}>
              <Route path="/permission-by-product" element={<PersonProductRoles />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoute;
