import { useInvoiceDetailQuery } from '@/app/services/invoiceAPI';
import { formatStringToReal } from '@helpers/currency';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface StandardDialogProps {
  title: string;
  content: string;
  open: boolean;
  onClose: () => void;
}

export default function TableDialog(props: StandardDialogProps) {
  const { title, content, open, onClose } = props;
  const { t } = useTranslation(['invoices', 'common']);
  const { data: detail } = useInvoiceDetailQuery({ id: content }, { skip: !content });

  const HeaderTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 'bold',
    paddingBottom: 1,
  }));

  const RowTableCell = styled(TableCell)({
    fontSize: 13,
  });

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={(event: React.SyntheticEvent, reason: string) => {
        if (reason === 'escapeKeyDown') {
          onClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 18, fontWeight: 600 }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off">
          <div>
            <TextField
              disabled
              id="detail.invoice.code"
              label={t('detail.invoice.code')}
              size="small"
              value={detail?.code || ' '}
            />
            <TextField
              disabled
              id="detail.invoice.erp.code"
              label={t('detail.invoice.erp.code')}
              value={detail?.codeErp || ' '}
              size="small"
            />
            <TextField
              disabled
              id="detail.invoice.branch"
              label={t('detail.invoice.branch')}
              value={detail?.branch || ' '}
              size="small"
            />
          </div>
        </Box>
        <TableContainer>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <HeaderTableCell>{t('common:project')}</HeaderTableCell>
                <HeaderTableCell>{t('common:item.type')}</HeaderTableCell>
                <HeaderTableCell>{t('common:description')}</HeaderTableCell>
                <HeaderTableCell>{t('common:price')}</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detail?.items?.map((row) => (
                <TableRow key={row.id.toString()}>
                  <RowTableCell> {row.project}</RowTableCell>
                  <RowTableCell> {row.type}</RowTableCell>
                  <RowTableCell> {row.person}</RowTableCell>
                  <RowTableCell> {formatStringToReal(row.price)}</RowTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', gap: 1, p: 3, borderTop: 1, borderTopColor: '#E4E4E4' }}>
        <Button
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {t('common:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
