import { ErpAgency } from '@models/erpAgency';
import { PaginationResponse } from '@models/pagination';
import api from '@services/api';
import { Agency } from '../models/agency';
import { LastSyncAgency } from '../models/lastSyncAgency';

/**
 * <p>
 * API endpoints that cover all resource project related CRUD calls
 */

interface ErpAgencyProject {
  searchTerm: string;
  page: number;
  size: number;
}

export const erpAgencyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    erpAgencies: builder.query<PaginationResponse<ErpAgency>, ErpAgencyProject>({
      query: (input) => ({
        url: encodeURI(
          `/travel/erpAgency/list?searchTerm=${input.searchTerm}&page=${input.page}&size=${input.size}&sortKey=codeAgencyErp&sortDirection=ASC`,
        ),
        method: 'GET',
      }),
      providesTags: ['ErpAgency'],
    }),
    // GET
    agenciesLastSync: builder.query<LastSyncAgency, void>({
      query: () => ({
        url: encodeURI(`/travel/agency/lastsync`),
        method: 'GET',
      }),
      providesTags: ['LastSyncAgency'],
    }),
    // GET
    getAllAgencies: builder.query<Array<Agency>, void>({
      query: () => ({
        url: encodeURI(`/travel/agency/listAll`),
        method: 'GET',
      }),
      providesTags: ['Agency'],
    }),
  }),
});

export const { useErpAgenciesQuery, useAgenciesLastSyncQuery, useGetAllAgenciesQuery } = erpAgencyApi;
