import InvoiceStatus from '@/app/models/invoiceStatus';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type StatusReportProps = {
  status: InvoiceStatus;
  success?: boolean;
  errorMessage?: string | null;
};

const getStatusColor = (status: string, success: boolean | undefined) => {
  switch (status) {
    case 'PROCESSED':
      if (success === false) {
        return '#FF0000';
      }
      return '#28A745';
    case 'SENT':
      return '#FFC107';
    default:
      return '#888888';
  }
};

const getStatusName = (status: string, success: boolean | undefined) => {
  switch (status) {
    case 'PROCESSED':
      if (success === false) {
        return 'ERROR';
      }
      return 'PROCESSED';
    default:
      return status;
  }
};

function StatusReport(props: StatusReportProps) {
  const { status, success, errorMessage } = props;
  const { t } = useTranslation('invoices');
  return (
    <Tooltip title={errorMessage || ''}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          style={{
            backgroundColor: `${getStatusColor(status || '', success)}`,
            width: '8px',
            height: '8px',
            borderRadius: '4px',
            marginRight: '6px',
          }}
        />
        <Typography sx={{ fontSize: 13, color: '#4B4B4B', margin: '0px' }} color="text.secondary" gutterBottom>
          {t(getStatusName(status, success) || ('...' as string))}
        </Typography>
      </div>
    </Tooltip>
  );
}

export default StatusReport;
