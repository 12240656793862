import api from '@services/api';
import { Product } from '../models/permissionProduct';

/**
 * <p>
 * API endpoints that cover all resource product related CRUD calls
 */

export const productapi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    Products: builder.query<[Product], void>({
      query: () => ({
        url: encodeURI(`/backoffice/products`),
        method: 'GET',
      }),
      providesTags: ['Products'],
    }),
  }),
});

export const { useProductsQuery } = productapi;
