import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { ErpAgency } from '@/app/models/erpAgency';
import { TablePagination, Typography } from '@mui/material';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});

type CreateExceptionTableProps = {
  rows: Array<ErpAgency>;
  page: number;
  setPage: (n: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (n: number) => void;
  count: number;
};

function AgenciesTable(props: CreateExceptionTableProps) {
  const { rows, count, rowsPerPage, setRowsPerPage, page, setPage } = props;
  const { t } = useTranslation(['agencies', 'common']);
  return (
    <TableContainer>
      <Table size="small" aria-label="Exception Case Table">
        <TableHead>
          <TableRow>
            <HeaderTableCell>{t('agency.code.erp')}</HeaderTableCell>
            <HeaderTableCell>{t('common:name')}</HeaderTableCell>
            <HeaderTableCell>{t('agency.prefix')}</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <RowTableCell>{row.codeAgencyErp}</RowTableCell>
              <RowTableCell>{row.agency.name}</RowTableCell>
              <RowTableCell>{`${row.agency.prefix}`}</RowTableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                <Typography variant="subtitle2">{t('not.exists.agencies')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        sx={{ width: '100%' }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => setPage(p)}
        onRowsPerPageChange={(e) => {
          console.log(e);
          setRowsPerPage(parseFloat(e.target.value));
          setPage(0);
        }}
        labelDisplayedRows={({ from, to, count: limit }) => `${from}-${to} ${t('common:of')} ${limit}`}
        labelRowsPerPage={t('common:itens.per.page')}
      />
    </TableContainer>
  );
}

export default AgenciesTable;
