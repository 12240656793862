/**
 * <p>
 * This function use as input a user name and generates
 * a two letters acronym according to the following rule:
 * first letter of the first and second name excluding connectors (de, da, do, etc).
 * For instance: Joaquim da Silva Gomes generates the acronym 'JS'
 * @param string
 * @returns string
 */
export default function getInitials(name: string): string {
  if (!name) return '';
  const connectors = [' DA ', ' DO ', ' DE ', ' D ', ' D. ', ' E ', ' DAS ', ' DOS ', ' Y '];
  let formattedName = name.toUpperCase();
  for (let i = 0; i < connectors.length; i += 1) {
    const connector = connectors[i];
    formattedName = formattedName.replace(connector, ' ');
  }
  const s = formattedName.trim().split(' ');
  const initials = s[0].charAt(0) + (s.length > 1 ? s[1].charAt(0) : '');
  return initials;
}
