import { InputAdornment, InputProps, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../icons/search.svg';

type CallbackFunction = (v: string) => void;
type SearchFieldProps = {
  searchTerm?: string;
  setSearchTerm: CallbackFunction;
  placeholder?: string;
  style?: object;
};

function SearchField(props: SearchFieldProps) {
  const { searchTerm, setSearchTerm, placeholder, style } = props;
  const { t } = useTranslation('common');
  return (
    <TextField
      size="small"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      style={{ minWidth: '360px', height: '32px', fontSize: '13px', ...style }}
      autoFocus
      placeholder={placeholder}
      InputProps={
        {
          endAdornment: (
            <InputAdornment position="end">
              <img src={searchIcon} alt={t('icon.search') as string} />
            </InputAdornment>
          ),
          style: {
            paddingRight: '10px',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '10px',
            fontSize: '13px',
          },
        } as InputProps
      }
    />
  );
}

export default SearchField;
