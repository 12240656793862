import { useErpProductsQuery } from '@/app/services/erpProductApi';
import SearchField from '@/components/common/SearchField';
import ProductsTable from '@/components/tables/ProductsTable';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ErpProducts() {
  const { t } = useTranslation('products');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: products } = useErpProductsQuery({ searchTerm, page, size: rowsPerPage });
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '15px',
      }}
    >
      <Typography sx={{ marginTop: '32px', marginBottom: '0px' }} variant="h1">
        {t('types.of.products')}
      </Typography>
      <SearchField
        style={{ marginTop: '16px', marginBottom: '16px', width: '30%' }}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder={t('search.by.fields.name.or.code') || ''}
      />
      <ProductsTable
        rows={products?.content || []}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={products?.totalElements as number}
      />
    </Box>
  );
}
export default ErpProducts;
