import { useErpAgenciesQuery } from '@/app/services/erpAgencyApi';
import SearchField from '@/components/common/SearchField';
import PageTitle from '@/components/common/pageTitle';
import AgenciesTable from '@/components/tables/AgenciesTable';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Agencies() {
  const { t } = useTranslation('agencies');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: agencies } = useErpAgenciesQuery({ searchTerm, page, size: rowsPerPage });
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '15px',
      }}
    >
      <PageTitle title={t('agencies')} />
      <SearchField
        style={{ marginTop: '16px', marginBottom: '16px', width: '30%' }}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder={t('search.by.name.or.erp-code') || ''}
      />
      <AgenciesTable
        rows={agencies?.content || []}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={agencies?.totalElements as number}
      />
    </Box>
  );
}
export default Agencies;
