/* eslint-disable react/jsx-props-no-spreading */
import getDb from '@/app/firebase/firebase';
import { getInvoiceProcessSubscription } from '@/app/helpers/firebaseCollections';
import ResponsibleInvoiceStatus from '@/app/models/responsibleInvoiceStatus';
import { usePendingInvoicesQuery, useProcessedInvoicesQuery } from '@/app/services/invoiceAPI';
import { useInvoicesProcessChangedStatusMutation } from '@/app/services/notificationsApi';
import { Badge } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { DocumentChange, collection, deleteDoc, onSnapshot, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ProcessedInvoicesTable from '../tables/ProcessedInvoicesTable';
import PendingInvoicesTable from '../tables/pendingInvoicesTable';
import CustomTabPanel from './customTabPanel';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: '50%',
    width: 25,
    transform: 'translateY(-50%)  translateX(120%)',
  },
}));

export default function InvoicesTabs() {
  const { t } = useTranslation(['invoices', 'common']);
  const [tab, setTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDisabledInvoiceNotification, setShowDisabledInvoiceNotification] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: totalInvoices } = usePendingInvoicesQuery({
    searchTerm: '',
    page,
    size: rowsPerPage,
  });
  const { data: pendingInvoices } = usePendingInvoicesQuery({
    searchTerm,
    page,
    size: rowsPerPage,
  });
  const { data: processedInvoices } = useProcessedInvoicesQuery({
    searchTerm,
    page,
    size: rowsPerPage,
  });

  const [invoicesProcessChangedStatus] = useInvoicesProcessChangedStatusMutation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSearchTerm('');
    setTab(newValue);
  };

  const listeningInvoicesProcessStatusUpdate = (): void => {
    const db = getDb();
    const q = query(collection(db, getInvoiceProcessSubscription()));
    onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((item: DocumentChange) => {
        if (item.type === 'added') {
          invoicesProcessChangedStatus();
          deleteDoc(item.doc.ref);
          toast.success(
            t('processed.invoices.updated', {
              position: toast.POSITION.TOP_RIGHT,
            }),
          );
        }
      });
    });
  };

  useEffect(() => {
    listeningInvoicesProcessStatusUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showDisabledInvoiceNotification) {
      toast.success(
        t('invoices.disabled.success', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      setShowDisabledInvoiceNotification(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDisabledInvoiceNotification, pendingInvoices]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="invoice tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab
            value={ResponsibleInvoiceStatus.PENDING}
            label={
              <CustomBadge badgeContent={totalInvoices?.totalElements.toString()} color="warning">
                {t('tab.pending')}
              </CustomBadge>
            }
            sx={{ textTransform: 'none', pr: 4, pb: 0 }}
            {...a11yProps(0)}
          />
          <Tab
            value={ResponsibleInvoiceStatus.PROCESSED}
            label={t('tab.processed')}
            sx={{ textTransform: 'none', pb: 0 }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={tab} index={0}>
        <PendingInvoicesTable
          rows={pendingInvoices?.content || []}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={pendingInvoices?.totalElements as number}
          search={setSearchTerm}
          searchTerm={searchTerm}
          notificationDisableInvoice={setShowDisabledInvoiceNotification}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <ProcessedInvoicesTable
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          rows={processedInvoices?.content || []}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={processedInvoices?.totalElements as number}
        />
      </CustomTabPanel>
    </Box>
  );
}
