import api from '@services/api';
import { PaginationResponse } from '../models/pagination';
import {
  Person,
  PersonByEmailAndProductRequest,
  PersonProductRole,
  PersonProductRoles,
  PersonRequest,
} from '../models/person';

/**
 * <p>
 * API endpoints that cover all resource sharing CRUD calls
 */
export const personApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    person: builder.query<PaginationResponse<Person>, PersonRequest>({
      query: (input: PersonRequest) => ({
        url: encodeURI(
          `/backoffice/person/searchByTerm?${input.searchTerm ? `searchTerm=${input.searchTerm}&` : ''}size=${
            input.size
          }&page=${input.page}`,
        ),
      }),
    }),
    personProductsRoles: builder.query<PaginationResponse<PersonProductRoles>, PersonRequest>({
      query: (input: PersonRequest) => ({
        url: encodeURI(
          `/backoffice/person-product-role/searchByTerm?${
            input.searchTerm ? `searchTerm=${input.searchTerm}&` : ''
          }size=${input.size}&page=${input.page}`,
        ),
      }),
    }),
    personProductsRolesByEmailProduct: builder.query<PersonProductRoles, PersonByEmailAndProductRequest>({
      query: (input: PersonByEmailAndProductRequest) => ({
        url: encodeURI(
          `/backoffice/person-product-role/searchByEmailAndProduct?email=${input.email}&productCode=${input.productCode}`,
        ),
      }),
    }),
    updatePersonProductRole: builder.mutation<PersonProductRole, PersonProductRole>({
      query: (personProductRole) => ({
        url: `/backoffice/person-product-role`,
        method: 'PUT',
        body: personProductRole,
      }),
    }),
  }),
});

export const {
  usePersonQuery,
  usePersonProductsRolesByEmailProductQuery,
  usePersonProductsRolesQuery,
  useUpdatePersonProductRoleMutation,
} = personApi;
