import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface StandardDialogProps {
  title: string;
  contentText: string;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void | null;
  cancelText: string;
  confirmText?: string | null;
  children?: string | JSX.Element | JSX.Element[];
  disableConfirm?: boolean;
}

export default function StandardDialog(props: StandardDialogProps) {
  const { title, contentText, open, onClose, onConfirm, cancelText, confirmText, children, disableConfirm } = props;

  return (
    <Dialog
      open={open}
      onClose={(event: React.SyntheticEvent, reason: string) => {
        if (reason === 'escapeKeyDown') {
          onClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 18, fontWeight: 600 }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: 14 }}>
          {contentText}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', gap: 1, p: 3, borderTop: 1, borderTopColor: '#E4E4E4' }}>
        <Button
          variant={confirmText ? 'secondary' : 'primary'}
          onClick={() => {
            onClose();
          }}
        >
          {cancelText}
        </Button>
        {confirmText && onConfirm && (
          <Button variant="primary" onClick={() => onConfirm()} disabled={disableConfirm || false} autoFocus>
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
