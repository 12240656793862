import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Redirect() {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation('login');

  // const isSuper = keycloak.hasResourceRole('SUPER', process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const isSuper = true;

  useEffect(() => {
    if (isSuper) {
      navigate('/process');
    } else {
      navigate('/process');
    }
  }, [isSuper, navigate]);

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Typography sx={{ marginTop: '42px', marginLeft: '50px' }}>{t('no.permission.user')}</Typography>
    </div>
  );
}
export default Redirect;
