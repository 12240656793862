import { formatReal } from '@/app/helpers/currency';
import { formatDate } from '@/app/helpers/date';
import { Invoice } from '@/app/models/invoice';
import InvoiceStatus from '@/app/models/invoiceStatus';
import { useProcessInvoicesMutation } from '@/app/services/invoiceAPI';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InfoIcon from '@mui/icons-material/Info';
import { Box, TablePagination, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ActionMenu, { ItemAction } from '../common/ActionMenu';
import SearchField from '../common/SearchField';
import StatusReport from '../common/StatusReport';
import TableDialog from '../common/dialogs/TableDialog';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});

type CreateExceptionTableProps = {
  rows: Array<Invoice>;
  page: number;
  setPage: (n: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (n: number) => void;
  count: number;
  setSearchTerm: (val: string) => void;
  searchTerm: string;
};

function ProcessedInvoicesTable(props: CreateExceptionTableProps) {
  const { rows, count, rowsPerPage, setRowsPerPage, page, setPage, setSearchTerm, searchTerm } = props;
  const { i18n, t } = useTranslation(['invoices', 'common']);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [openReprocessDialog, setOpenReprocessDialog] = React.useState(false);
  const [processInvoices] = useProcessInvoicesMutation();
  const [openInvoiceDetailDialog, setOpenInvoiceDetailDialog] = useState(false);
  const [invoiceDetailId, setInvoiceDetailId] = useState('');
  const [isReprocess, setIsReprocess] = React.useState(false);

  const handleReprocess = async (id: number[]) => {
    const response = await processInvoices({ ids: id });
    if ('data' in response) {
      toast.success(
        t('invoices.reprocess.sent', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, value: string) => {
    const selectedIndex = selected.indexOf(value);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: string[] = rows.map((n) => n.id.toString());
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleInvoiceDetailModal = async (id: string) => {
    setInvoiceDetailId(id);
    setOpenInvoiceDetailDialog(true);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          marginTop: '16px',
          marginBottom: '16px',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <SearchField
          style={{ width: '30%' }}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder={t('search.by.agency.or.invoice-id') || ''}
        />
      </Box>
      <TableDialog
        title={t('common:detail')}
        content={invoiceDetailId}
        open={openInvoiceDetailDialog}
        onClose={() => {
          setOpenInvoiceDetailDialog(false);
        }}
      />
      <TableContainer>
        <Table size="small" aria-label="Exception Case Table">
          <TableHead>
            <TableRow>
              <HeaderTableCell>{t('invoice.code')}</HeaderTableCell>
              <HeaderTableCell>{t('invoice.erp.code')}</HeaderTableCell>
              <HeaderTableCell>{t('agency')}</HeaderTableCell>
              <HeaderTableCell>{t('total.expenses')}</HeaderTableCell>
              <HeaderTableCell>{t('invoice.date.collected')}</HeaderTableCell>
              <HeaderTableCell>{t('invoice.date.processed')}</HeaderTableCell>
              <HeaderTableCell>{t('invoice.status')}</HeaderTableCell>
              <HeaderTableCell>{t('actions')}</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                role="checkbox"
                aria-checked={isSelected(row.id.toString())}
                tabIndex={-1}
                key={row.id.toString()}
              >
                <RowTableCell> {row.code}</RowTableCell>
                <RowTableCell> {row.erpInvoiceCode}</RowTableCell>
                <RowTableCell> {row.agency.name}</RowTableCell>
                <RowTableCell> {formatReal(parseFloat(row.totalExpenses))} </RowTableCell>
                <RowTableCell>{formatDate(row.collectedDate.toString(), i18n.language)} </RowTableCell>
                <RowTableCell>
                  {row?.processedDate && row?.status === InvoiceStatus.PROCESSED
                    ? formatDate(row?.processedDate?.toString(), i18n.language)
                    : '---'}{' '}
                </RowTableCell>
                <RowTableCell>
                  <StatusReport
                    status={row.status as InvoiceStatus}
                    errorMessage={row.errorDetail}
                    success={row.success}
                  />
                </RowTableCell>
                <RowTableCell>
                  <ActionMenu>
                    <ItemAction
                      text={t('reprocess')}
                      Icon={AutorenewIcon}
                      DividerLine
                      onClick={() => {
                        if (!isReprocess) {
                          setIsReprocess(true);
                          handleReprocess([row.id]);
                        }
                        setTimeout(() => {
                          setIsReprocess(false);
                        }, 500);
                      }}
                    />
                    <ItemAction
                      text={t('common:detail')}
                      Icon={InfoIcon}
                      onClick={() => {
                        handleInvoiceDetailModal(row.id.toString());
                      }}
                    />
                  </ActionMenu>
                </RowTableCell>
              </TableRow>
            ))}
            {rows.length === 0 && (
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                  <Typography variant="subtitle2">{t('not.exists.invoices.processed')}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          sx={{ width: '100%' }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => setPage(p)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseFloat(e.target.value));
            setPage(0);
          }}
          labelDisplayedRows={({ from, to, count: limit }) => `${from}-${to} ${t('common:of')} ${limit}`}
          labelRowsPerPage={t('common:itens.per.page')}
        />
      </TableContainer>
    </Box>
  );
}

export default ProcessedInvoicesTable;
