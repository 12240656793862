import type { KeycloakInstance } from 'keycloak-js';
/**
 * <p>
 * This function check wether a user has at least one role
 * of a set of roles.
 * @param string
 * @returns string
 */
export default function isAuthorized(keycloak: KeycloakInstance, roles: Array<string>): boolean {
  if (keycloak && roles && keycloak.token) {
    return roles.some((r: string) => {
      // In keycloak there are two ways of assiging roles to the user
      // You can assign roles to realm & client
      // In that case you have to use both scinarios with hasRealmRole & hasResourceRole
      const realm = keycloak.hasRealmRole(r);
      const resource = keycloak.hasResourceRole(r);
      return realm || resource;
    });
  }
  return false;
}
