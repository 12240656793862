import { PaginationResponse } from '@models/pagination';
import api from '@services/api';
import { DetailInvoiceItem } from '../models/DetailinvoiceItem';
import { AgencySyncRequest } from '../models/agencySyncRequest';
import { Invoice } from '../models/invoice';
import InvoiceStatus from '../models/invoiceStatus';
import { InvoicesIdsRequest } from '../models/invoicesIdsRequest';
import { InvoicesProcessRequest } from '../models/invoicesProcessRequest';

/**
 * <p>
 * API endpoints that cover all resource project related CRUD calls
 */

interface InvoiceRequest {
  searchTerm: string;
  page: number;
  size: number;
}
interface InvoiceDetailRequest {
  id: string;
}

export const invoiceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    pendingInvoices: builder.query<PaginationResponse<Invoice>, InvoiceRequest>({
      query: (input) => ({
        url: encodeURI(
          `/travel/invoice/search?status=${InvoiceStatus.PENDING}&searchTerm=${input.searchTerm}&page=${input.page}&size=${input.size}&sortKey=code&sortDirection=ASC`,
        ),
        method: 'GET',
      }),
      providesTags: ['InvoicePending'],
    }),
    // GET
    processedInvoices: builder.query<PaginationResponse<Invoice>, InvoiceRequest>({
      query: (input) => ({
        url: encodeURI(
          `/travel/invoice/search?status=${[InvoiceStatus.PROCESSED, InvoiceStatus.SENT].join(',')}&searchTerm=${
            input.searchTerm
          }&page=${input.page}&size=${input.size}&sortKey=processeddate&sortDirection=DESC`,
        ),
        method: 'GET',
      }),
      providesTags: ['InvoiceProcessed'],
    }),
    // GET
    invoiceDetail: builder.query<DetailInvoiceItem, InvoiceDetailRequest>({
      query: (input) => ({
        url: encodeURI(`/travel/invoice/details?id=${input.id}`),
        method: 'GET',
      }),
      providesTags: ['InvoiceDetail'],
    }),
    // POST
    synchronizeInvoices: builder.mutation<void, AgencySyncRequest>({
      query: (input: AgencySyncRequest) => ({
        url: encodeURI('/travel/invoice/synchronize'),
        method: 'POST',
        body: input,
      }),
    }),
    // POST
    processInvoices: builder.mutation<void, InvoicesProcessRequest>({
      query: (input: InvoicesProcessRequest) => ({
        url: encodeURI('/travel/invoice/process'),
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['InvoicePending', 'InvoiceProcessed'],
    }),
    // DELETE
    disableInvoice: builder.mutation<void, InvoicesIdsRequest>({
      query: (id) => ({
        url: `/travel/invoice/disable`,
        method: 'DELETE',
        body: id,
      }),
      invalidatesTags: ['InvoicePending'],
    }),
  }),
});

export const {
  usePendingInvoicesQuery,
  useSynchronizeInvoicesMutation,
  useProcessedInvoicesQuery,
  useProcessInvoicesMutation,
  useInvoiceDetailQuery,
  useDisableInvoiceMutation,
} = invoiceApi;
