enum FirebaseCollections {
  INVOICE_REQUEST = 'sync-message',
  INVOICE_PROCESS = 'integration-message',
}

const getInvoiceRequestSubscription = (): string =>
  `${FirebaseCollections.INVOICE_REQUEST}-${process.env.VITE_APP_ENV_COLLECTION}`;

const getInvoiceProcessSubscription = (): string =>
  `${FirebaseCollections.INVOICE_PROCESS}-${process.env.VITE_APP_ENV_COLLECTION}`;

export { getInvoiceProcessSubscription, getInvoiceRequestSubscription };
