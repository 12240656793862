import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TreeView from '@mui/lab/TreeView';
import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarItem from './SidebarItem';

export default function Sidebar() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['sidebar', 'common']);
  const isAdmin =
    keycloak.hasResourceRole('SUPER', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || process.env.REACT_APP_ENV === 'dev';

  const selectedTree = (endpoint: string) => {
    switch (endpoint) {
      case '/process':
        return '1';
      case '/agencies':
        return '2';
      case '/products':
        return '3';
      case '/permission-by-product':
        return '4';
      default:
        return '1';
    }
  };
  return (
    <TreeView
      selected={selectedTree(location.pathname)}
      defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, width: 280, paddingLeft: '2rem' }}
    >
      <SidebarItem
        active={selectedTree(location.pathname) === '1'}
        nodeId="1"
        labelText={t('common:process')}
        labelInfo="2,294"
        color="#e3742f"
        bgColor="#fcefe3"
        badgeColor="warning"
        onClick={() => {
          navigate('process');
        }}
      />
      <Typography variant="h2" sx={{ marginY: '1rem', fontWeight: 'bold' }}>
        {t('register')}
      </Typography>
      <SidebarItem
        active={selectedTree(location.pathname) === '2'}
        nodeId="2"
        labelText={t('common:agencies')}
        color="#e3742f"
        bgColor="#fcefe3"
        onClick={() => {
          navigate('agencies');
        }}
      />
      <SidebarItem
        active={selectedTree(location.pathname) === '3'}
        nodeId="3"
        labelText={t('products')}
        color="#e3742f"
        bgColor="#fcefe3"
        onClick={() => {
          navigate('products');
        }}
      />
      {isAdmin && (
        <>
          <Typography variant="h2" sx={{ marginY: '1rem', fontWeight: 'bold' }}>
            {t('administration')}
          </Typography>
          <SidebarItem
            active={selectedTree(location.pathname) === '4'}
            nodeId="4"
            labelText={t('product.permission')}
            color="#e3742f"
            bgColor="#fcefe3"
            onClick={() => {
              navigate('permission-by-product');
            }}
          />
        </>
      )}
    </TreeView>
  );
}
