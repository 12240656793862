import Keycloak from 'keycloak-js';

/**
 * <p>
 * Here we have the minimal keycloak configurations that will
 * be used by the ReactKeycloakProvider service that in turn
 * will care about all comunication with the oauth service for
 * a specific realm and client.
 *
 * All those mandatories properties can be found into the .env
 * file, in the production deploy, you may want to use a more
 * flexibe way to set those values.
 */
const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
} as Keycloak.KeycloakConfig;

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
