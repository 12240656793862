import { AuthClient } from '@app/models/authClient';
import { store } from '@app/store';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux/es/exports';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './index.css';
import keycloak from './keycloak';

const container = document.getElementById('root');
const root = createRoot(container!);

const tokenLogger = (tokens: AuthClient) => {
  const token = tokens?.token;

  if (token !== 'undefined') {
    localStorage.setItem('token', token!);
  }
};

root.render(
  <ReactKeycloakProvider authClient={keycloak} onTokens={tokenLogger}>
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </ErrorBoundary>
  </ReactKeycloakProvider>,
);
