import { createSlice } from '@reduxjs/toolkit';

type ThemeInitialState = {
  mode: string;
};

const initialState: ThemeInitialState = {
  mode: 'light', // default mode, light!
};
/**
 * Create a global slice (reducer) to change the
 * application theme mode.
 */
export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setMode: (state) => {
      const st = state;
      st.mode = state.mode === 'light' ? 'dark' : 'light';
    },
  },
});

/** Expose the setMode(state) as a global function */
export const { setMode } = globalSlice.actions;

/** Expose the reducer, so you can use in useSelector() hook */
export default globalSlice.reducer;
