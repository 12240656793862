const Real = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const formatReal = (input: number) => Real.format(input);
const formatStringToReal = (input: string) => Real.format(parseFloat(input));

// eslint-disable-next-line import/prefer-default-export
export { formatReal, formatStringToReal };
