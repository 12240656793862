import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * <p>
 * There are quite a few ways that you can manage your errors, and in this cases,
 * we want to show a generic toast notification for any async error. Being that
 * RTK Query is built on top of Redux and Redux-Toolkit, we can easily add a
 * middleware to our store for this purpose.
 *
 * Here we're using the React Toastify component, that will be the generic component
 * to show the global error message.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const notify = () => {
      toast.error(`${action?.payload?.status} - ${action?.payload?.data?.errorMessage}`, {
        position: toast.POSITION.TOP_RIGHT,
        delay: 1,
      });
    };
    notify();
  }
  return next(action);
};

export default rtkQueryErrorLogger;
