import { ErpProduct } from '@models/erpProduct';
import { PaginationResponse } from '@models/pagination';
import api from '@services/api';

/**
 * <p>
 * API endpoints that cover all resource project related CRUD calls
 */

interface ErpProductRequest {
  searchTerm: string;
  page: number;
  size: number;
}

export const erpProductApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    erpProducts: builder.query<PaginationResponse<ErpProduct>, ErpProductRequest>({
      query: (input) => ({
        url: encodeURI(
          `/travel/product/search?searchTerm=${input.searchTerm}&page=${input.page}&size=${input.size}&sortKey=name&sortDirection=ASC`,
        ),
        method: 'GET',
      }),
      providesTags: ['ErpAgency'],
    }),
  }),
});

export const { useErpProductsQuery } = erpProductApi;
