import getDb from '@/app/firebase/firebase';
import { formatDate, formatTime } from '@/app/helpers/date';
import { getInvoiceRequestSubscription } from '@/app/helpers/firebaseCollections';
import { AgencySyncRequest } from '@/app/models/agencySyncRequest';
import { useAgenciesLastSyncQuery, useGetAllAgenciesQuery } from '@/app/services/erpAgencyApi';
import { useSynchronizeInvoicesMutation } from '@/app/services/invoiceAPI';
import { useDataChangedStatusMutation } from '@/app/services/notificationsApi';
import { Box, Button, CircularProgress } from '@mui/material';
import { DocumentChange, collection, deleteDoc, onSnapshot, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StandardDialog from '../common/StandardDialog';

enum AgencySyncStatus {
  SENT = 'SENT',
  PROCESSED = 'PROCESSED',
}

function AgencySynchronizeStatus() {
  const { i18n, t } = useTranslation(['agencies', 'invoices', 'common']);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [messageStatus, setMessageStatus] = useState('');
  const [isSynchronization, setIsSynchronization] = useState(false);
  const [synchronizeInvoicesAgency] = useSynchronizeInvoicesMutation();
  const { data: agencies } = useGetAllAgenciesQuery();
  const { data: lastSync } = useAgenciesLastSyncQuery();
  const [dataChangedStatus] = useDataChangedStatusMutation();

  const isWaitingSync = agencies?.some((agencie) => agencie.syncStatus === AgencySyncStatus.SENT);
  const isProcessedSync = agencies?.some((agencie) => agencie.syncStatus === AgencySyncStatus.PROCESSED);

  const defineStatusMessage = (): void => {
    if (isWaitingSync || isSynchronization) {
      setMessageStatus(t('wait.the.invoices.synchronization').toString());
      return;
    }
    if (isProcessedSync) {
      setMessageStatus(
        t('agency.moment.last.sync').replace(
          '{0}',
          `${formatDate(lastSync?.lastDate, i18n.language)} - ${formatTime(lastSync?.lastDate)}`,
        ),
      );
      return;
    }
    if (isWaitingSync === false && isProcessedSync === false)
      setMessageStatus(t('agency.moment.not.exists.sync').toString());
  };

  const synchronize = async () => {
    const request: AgencySyncRequest = { names: agencies ? agencies?.map((a) => a.name) : [] };
    await synchronizeInvoicesAgency(request);
    setIsSynchronization(true);
    defineStatusMessage();
  };

  const listeningStatusUpdate = (): void => {
    const db = getDb();
    const q = query(collection(db, getInvoiceRequestSubscription()));
    onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((item: DocumentChange) => {
        if (item.type === 'added') {
          dataChangedStatus();
          setIsSynchronization(false);
          deleteDoc(item.doc.ref);
        }
      });
    });
  };

  useEffect(() => {
    listeningStatusUpdate();
    defineStatusMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies, isSynchronization, lastSync]);

  return (
    <Box sx={{ display: 'flex', align: 'Space-between', alignItems: 'center' }}>
      <StandardDialog
        title={t('button.synchronize')}
        contentText={t('confirmation.sync.agency.invoices')}
        open={openSaveDialog}
        onClose={() => {
          setOpenSaveDialog(false);
        }}
        onConfirm={() => {
          setOpenSaveDialog(false);
          synchronize();
        }}
        cancelText={t('common:no')}
        confirmText={t('common:yes')}
      />

      <div
        style={{
          marginRight: '20px',
          fontSize: '13px',
        }}
      >
        {messageStatus}
      </div>

      <Button
        size="small"
        onClick={() => setOpenSaveDialog(true)}
        variant="secondary"
        style={{
          marginLeft: '16px',
        }}
      >
        {isWaitingSync || isSynchronization ? (
          <CircularProgress size={24} color="secondary" />
        ) : (
          <>{t('button.synchronize')}</>
        )}
      </Button>
    </Box>
  );
}

export default AgencySynchronizeStatus;
