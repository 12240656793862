import api from '@services/api';

export const notificationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    dataChangedStatus: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['InvoicePending', 'LastSyncAgency', 'Agency'],
    }),
    invoicesProcessChangedStatus: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['InvoicePending', 'InvoiceProcessed'],
    }),
  }),
});

export const { useDataChangedStatusMutation, useInvoicesProcessChangedStatusMutation } = notificationsApi;
